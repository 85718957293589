import { Grid } from '@mui/material';
import { ObjectFieldTemplateProps } from '@rjsf/utils';

export const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
    return <Grid
      container
      direction="column"
    >
      {props.uiSchema?.['ui:grid'].map((row: any, idx: number) =>
        <Grid
          item
          container
          direction="row"
          p={2}
          key={idx}
        >
          {Object.keys(row).map((fieldName, oi: number) =>
          <Grid item {...row[fieldName]} key={oi}>
            {props?.properties?.find(p=>p.name===fieldName)?.content}
          </Grid>)}
        </Grid>)}
    </Grid>
}