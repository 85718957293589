import { type EstimateBody } from "@artaio/arta-browser";

export async function getObjectAndLocation(): Promise<EstimateBody> {
    return {
        origin: {
            address_line_1: "358 Wythe Ave",
            city: "Brooklyn",
            region: "NY",
            country: "US",
            postal_code: "11249"
        },
        objects: [
            {
              depth: 2,
              width: 36,
              height: 24,
              subtype: "painting_unframed",
              unit_of_measurement: "in",
              weight_unit: "lb",
              value_currency: "USD",
              value: 100.00,
              weight: 1
            }
        ]
    }
}
