import React from 'react';
import './index.css';
import Arta, { type PartialEstimateConfig } from '@artaio/arta-browser';
import { getObjectAndLocation } from './data';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';
// import _ from 'lodash';
import { schema, uiSchema } from './schema';

export default function App() {
  const [alive, setAlive] = React.useState(false);
  const [formData, setFormData] = React.useState<PartialEstimateConfig>({
    style: {
      color: {
        background: '#FFFFFF',
        textPrimary: '#110F10',
        textSecondary: '#6F6C65',
        border: '#D7D6D0',
        borderHover: '#6F6C65',
        borderFocused: '#110F10',
        buttonBackground: '#110F10',
        buttonBackgroundHover: '#6F6C65',
        buttonBackgroundDisabled: '#F2F2F2',
        buttonText: '#FFFFFF',
        buttonTextHover: '#FFFFFF',
        buttonTextDisabled: '#9D9D9D',
        quoteBackground: '#F2F2F2',
        errorText: '#822828',
        errorBackground: '#FBECEC',
      },
      position: 'right',
      pricingDisplay: 'starts_at',
      fontFamily: 'Arial, sans-serif',
      fontSize: 14,
      width: 320,
      height: 467,
    },
    text: {
      detailOriginLabel: '(origin)',
      detailDestinationLabel: '(destination)',
      returnLinkLabel: 'Change Destination',
      header: {
        title: 'Estimate shipping costs',
      },
      destination: {
        descriptionLabel: 'Get a cost estimate to ship these goods from:',
        buttonText: 'Get Costs',
        countryLabel: 'Country',
        zipLabel: 'Destination Postal/Zip Code',
        cityLabel: 'Destination City',
      },
      quoted: {
        shipFromLabel: 'These goods ship from:',
        shipToLabel: 'To:',
        disclaimerLabel: 'Actual shipping costs will be provided at checkout.',
        rangeLabel: 'Shipping estimated between',
        startsAtLabel: 'Shipping Starts at',
        artaInsuranceLabel: 'This estimate includes ARTA Transit Insurance.',
      },
      disqualified: {
        shipFromLabel:
          'Unfortunately we could not retrieve costs for shipping these goods from:',
        shipToLabel: 'To:',
        contactEmail: 'hello@arta.io',
        emailHeaderLabel: 'Please contact',
        emailFooterLabel: 'to request a custom quote',
      },
    },
  });

  React.useEffect(() => {
    const key = process.env.REACT_APP_ARTA_PUBLISHABLE_KEY;
    if (key != null && !alive) {
      Arta.init(key);
      setAlive(true);
    }

    const rerender = async () => {
      const objectsAndLocation = await getObjectAndLocation();
      const est = Arta.estimate(objectsAndLocation, formData);
      est.open();
    };
    if (alive) {
      rerender();
    }
  }, [alive, formData]);

  // const debouncedSetFormData = React.useCallback(
  //   _.debounce((data) => setFormData(data), 300), // Adjust the delay as needed (300 milliseconds in this example)
  //   []
  // );

  if (!alive) {
    return (
      <h1 className="text-3xl font-bold underline">
        No key provided!
      </h1>
    );
  }

  return (
    <div style={{width: '50%'}}>
    <Form
      validator={validator}
      schema={schema}
      uiSchema={uiSchema}
      formData={formData}
      onChange={(e) => setFormData(e.formData)}
    >
      <div/>
    </Form>
    </div>
  );
}
