import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { ObjectFieldTemplate } from "./ObjectFieldTemaplte";

export const schema: RJSFSchema = {
  title: 'Estimate Configuration',
  type: 'object',
  properties: {
    style: {
      type: 'object',
      title: 'Style',
      properties: {
        color: {
          type: 'object',
          title: 'Color',
          properties: {
            textPrimary: { type: 'string' },
            textSecondary: { type: 'string' },
            border: { type: 'string' },
            borderHover: { type: 'string' },
            quoteBackground: { type: 'string' },
            borderFocused: { type: 'string' },
            buttonBackground: { type: 'string' },
            buttonBackgroundHover: { type: 'string' },
            buttonBackgroundDisabled: { type: 'string' },
            buttonText: { type: 'string' },
            buttonTextHover: { type: 'string' },
            buttonTextDisabled: { type: 'string' },
            errorText: { type: 'string' },
            errorBackground: { type: 'string' },
            background: { type: 'string' },
          },
        },
        position: { type: 'string', enum: ['center', 'left', 'right'] },
        pricingDisplay: { type: 'string', enum: ['starts_at', 'range'] },
        fontFamily: { type: 'string' },
        fontSize: { type: 'number' },
        width: { type: 'number' },
        height: { type: 'number' },
      },
    },
    text: {
      type: 'object',
      title: 'Text',
      properties: {
        detailOriginLabel: { type: 'string' },
        detailDestinationLabel: { type: 'string' },
        returnLinkLabel: { type: 'string' },
        header: {
          type: 'object',
          title: 'Header',
          properties: {
            title: { type: 'string' },
          },
        },
        destination: {
          type: 'object',
          title: 'Destination',
          properties: {
            descriptionLabel: { type: 'string' },
            buttonText: { type: 'string' },
            countryLabel: { type: 'string' },
            zipLabel: { type: 'string' },
            cityLabel: { type: 'string' },
          },
        },
        quoted: {
          type: 'object',
          title: 'Quoted',
          properties: {
            shipFromLabel: { type: 'string' },
            shipToLabel: { type: 'string' },
            disclaimerLabel: { type: 'string' },
            rangeLabel: { type: 'string' },
            startsAtLabel: { type: 'string' },
            artaInsuranceLabel: { type: 'string' },
          },
        },
        disqualified: {
          type: 'object',
          title: 'Disqualified',
          properties: {
            shipFromLabel: { type: 'string' },
            shipToLabel: { type: 'string' },
            contactEmail: { type: 'string' },
            emailHeaderLabel: { type: 'string' },
            emailFooterLabel: { type: 'string' },
          },
        },
      },
    },
  },
  required: ['style', 'text'],
};



export const uiSchema: UiSchema = {
  style: {
    color: {
      'ui:ObjectFieldTemplate': ObjectFieldTemplate,
      'ui:grid': [{
        textPrimary: {
          xs: 2,
          mb: 2,
        },
        textSecondary: {
          xs: 2
        },
        border: {
          xs: 2
        },
        borderHover: { xs: 2 },
        quoteBackground: { xs: 2 },
        borderFocused: { xs: 2 },
        buttonBackground: { xs: 2 },
        buttonBackgroundHover: { xs: 2 },
        buttonBackgroundDisabled: { xs: 2 },
        buttonText: { xs: 2 },
        buttonTextHover: { xs: 2 },
        buttonTextDisabled: { xs: 2 },
        errorText: {
          xs: 2,
          mt: 2,
        },
        errorBackground: {
          xs: 2,
          mt: 2,
        },
        background: {
          xs: 2,
          mt: 2,
        },
      }],

      textPrimary: {
        'ui:widget': 'color',
      },
      textSecondary: {
        'ui:widget': 'color',
      },
      quoteBackground: {
        'ui:widget': 'color',
      },
      border: {
        'ui:widget': 'color',
      },
      borderHover: {
        'ui:widget': 'color'
      },
      borderFocused: {
        'ui:widget': 'color'
      },
      buttonBackground: {
        'ui:widget': 'color'
      },
      buttonBackgroundHover: {
        'ui:widget': 'color'
      },
      buttonBackgroundDisabled: {
        'ui:widget': 'color'
      },
      buttonText: {
        'ui:widget': 'color'
      },
      buttonTextHover: {
        'ui:widget': 'color'
      },
      buttonTextDisabled: {
        'ui:widget': 'color'
      },
      errorText: {
        'ui:widget': 'color',
      },
      errorBackground: {
        'ui:widget': 'color',
      },
      background: {
        'ui:widget': 'color',
      },
    },
    'ui:ObjectFieldTemplate': ObjectFieldTemplate,
    'ui:grid': [{
      color: {
        xs: 12,
      },
      position: {
        xs: 2,
      },
      pricingDisplay: {
        xs: 2,
      },
      fontFamily: {
        xs: 3.5,
      },
      fontSize: {
        xs: 1.5,
      },
      width: {
        xs: 1.5,
      },
      height: {
        xs: 1.5,
      }
    }],
    position: {
      'ui:widget': 'radio',
    },
    pricingDisplay: {
      'ui:widget': 'radio',
    },
  },
};